<template>
  <!-- Form with OTP -->
  <form @submit.prevent="switchRoute">
    <div v-if="switchUsername" class="mb-3">
      <!-- <label for="email" class="form-label">Email</label> -->
      <input
        type="email"
        id="email_field"
        class="form-control"
        placeholder="Email"
        v-model.trim="email"
        ref="focusEmailField"
        aria-describedby="emailHelp"
        :readonly="isUsernameVerified || isProcessing"
      />
      <div
        class="input-errors"
        v-for="error of v$.email.$errors"
        :key="error.$uid"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div v-else class="mb-3 row">
      <div class="col-md-6">
        <div>
          <!-- <label for="country_code" class="form-label">Country Code</label> -->
          <Multiselect
            v-model="countryCodeOptions.value"
            v-bind="countryCodeOptions"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.countryCodeOptions.value.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <!-- <label for="phone" class="form-label">Phone Number</label> -->
          <input
            type="text"
            id="phone_field"
            class="form-control"
            placeholder="Phone no. without country code"
            v-model.number="phone"
            ref="focusPhoneField"
            aria-describedby="phoneHelp"
            :readonly="isUsernameVerified || isProcessing"
          />
        </div>
        <div
          class="input-errors"
          v-for="error of v$.phone.$errors"
          :key="error.$uid"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div v-if="isUsernameVerified" class="mb-3 pass-input">
      <span class="input-actions">
        <a
          href="javascript:void(0);"
          @click="requestOTP"
          v-if="isOTPSent && !isProcessing"
          >Resend OTP</a
        >
      </span>
      <!-- <label for="otp" class="form-label">otp</label> -->
      <input
        type="text"
        id="otp_field"
        class="form-control"
        placeholder="- - - - -"
        v-model.trim="otp"
        ref="focusOTPField"
        :readonly="isProcessing"
      />
      <div
        class="input-errors"
        v-for="error of v$.otp.$errors"
        :key="error.$uid"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="text-right cta-section">
      <button
        v-if="isOTPSent"
        type="submit"
        class="btn cta-primary"
        :disabled="this.$store.state.auth.isAuthenticating == true"
      >
        <span v-if="this.$store.state.auth.isAuthenticating == false">
          Log In
        </span>
        <span v-else> Logging In </span>
      </button>
      <button
        v-if="!isOTPSent"
        :disabled="isProcessing"
        type="submit"
        class="btn cta-primary"
      >
        <span v-if="!isProcessing"> Send OTP </span>
        <span v-else> Sending OTP... </span>
      </button>
      <a
        class="btn cta-clear"
        v-if="isAllReadyLoggedIn == true"
        :disabled="isClearingSession == true"
        @click="logoutAllDevices"
      >
        <span v-if="isClearingSession == false"> Logout all sessions</span>
        <span v-else>Logging out all sessions... </span>
      </a>
    </div>
  </form>
</template>

<script>
import AuthService from "@/services/AuthService";
import CommonService from "@/services/CommonService";
import useVuelidate from "@vuelidate/core";
import {
  required,
  // requiredIf,
  email,
  numeric,
  maxLength,
  minLength,
  helpers,
} from "@vuelidate/validators";
import Multiselect from "@vueform/multiselect";

export default {
  name: "LoginOTPForm",
  components: { Multiselect },
  props: {
    switchUsername: Boolean,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      email: "",
      phone: "",
      countryCode: "",
      otp: "",
      isProcessing: false,
      isOTPSent: false,
      isUsernameVerified: false,
      authStatus: "",
      token: "",
      user: "",
      userId: "",
      isAllReadyLoggedIn: false,
      isClearingSession: false,
      // Select options
      countryCodeOptions: {
        value: null,
        options: [],
        placeholder: "Select Country Code",
        canDeselect: false,
        loading: true,
        // required: true,
        searchable: true,
        disabled: false,
      },
      // For taking the settimeout id
      inputFieldFocusTimer: 0,
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Please enter email", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      countryCodeOptions: {
        value: {
          required: helpers.withMessage("Please select country code", required),
        },
      },
      phone: {
        required: helpers.withMessage(
          "Please enter registered phone no.",
          required
        ),
        numeric: helpers.withMessage(
          "Please enter a valid contact no.",
          numeric
        ),
        maxLength: helpers.withMessage(
          "Max of 15 digits allowed",
          maxLength(15)
        ),
        minLength: helpers.withMessage(
          "It should be atleast of 6 digits",
          minLength(6)
        ),
      },
      otp: {
        // Not working the requiredIf validation as expected
        // requiredIfUsernameVerified: helpers.withMessage(
        //   "Please enter otp",
        //   requiredIf(this.isUsernameVerified)
        // ),
        required: helpers.withMessage("Please enter otp", required),
      },
    };
  },
  mounted() {
    this.v$.$reset(); // reset all validations
    if (document.querySelector("#email_field")) {
      this.$refs.focusEmailField.focus();
    } else if (document.querySelector("#phone_field")) {
      this.$refs.focusPhoneField.focus();
    }
    this.allCountries();
  },
  watch: {
    // Note:- switchUsername is a prop (variable) & newVal is its current value
    switchUsername(newVal) {
      // console.log(newVal);
      this.v$.$reset(); // reset all validations
      // Important:- To clear the setTimeout created before which didn't executed yet
      clearTimeout(this.inputFieldFocusTimer);
      this.isUsernameVerified = false;
      this.isOTPSent = false;
      this.isProcessing = false;
      this.otp = "";
      if (newVal === true) {
        this.phone = "";
        this.inputFieldFocusTimer = setTimeout(() => {
          this.$refs.focusEmailField.focus();
        }, 1000);
      } else {
        this.email = "";
        this.inputFieldFocusTimer = setTimeout(() => {
          this.$refs.focusPhoneField.focus();
        }, 1000);
      }
    },
  },
  methods: {
    async allCountries() {
      await CommonService.getAllCountries()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.countryCodeOptions.options = []; // to clear previously loaded options
            this.countryCodeOptions.loading = false;
            let countries = response.data.countries;
            if (countries.length > 0) {
              countries.map((country) => {
                let options = {
                  value: country.phone_code,
                  label: country.country_name + " (" + country.phone_code + ")",
                };
                this.countryCodeOptions.options.push(options);
              });
              this.countryCodeOptions.value = "+91";
            }
            // console.log(this.countryCodeOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // Note:- this is to control the enter key button press by user without clicking on any submit button
    switchRoute() {
      if (!this.isOTPSent) {
        this.requestOTP();
      } else {
        this.loginWithOTP();
      }
    },
    async requestOTP() {
      let params = {};
      // Custom field validation
      if (this.switchUsername) {
        this.v$.email.$touch();
        if (this.v$.email.$invalid) {
          return;
        } else {
          params = {
            email: this.email,
          };
        }
      } else {
        this.v$.countryCodeOptions.$touch();
        this.v$.phone.$touch();
        if (this.v$.countryCodeOptions.$invalid || this.v$.phone.$invalid) {
          return;
        } else {
          params = {
            country_code: this.countryCodeOptions.value,
            phone: this.phone,
          };
        }
      }
      this.$emit("processingFormSubmission", true);
      this.countryCodeOptions.disabled = true;
      this.isOTPSent = false;
      this.isProcessing = true;
      await AuthService.requestLoginOTP(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.isUsernameVerified = true;
            this.$emit("processingFormSubmission", false);
            this.countryCodeOptions.disabled = false;
            this.isOTPSent = true;
            setTimeout(() => {
              this.isProcessing = false;
              this.$toast.success(response.data.message);
              if (document.querySelector("#otp_field")) {
                this.$refs.focusOTPField.focus();
              }
            }, 5000);
          } else if (response.data.status === "ERROR") {
            this.$emit("processingFormSubmission", false);
            this.countryCodeOptions.disabled = false;
            this.$toast.error(response.data.message);
          } else if (response.data.status === "TOKEN_ERROR") {
            // Note:- TOKEN_ERROR status is commented at the moment
            this.userId = response.data.user_id;
            this.isAllReadyLoggedIn = true;
            this.$emit("processingFormSubmission", false);
            this.countryCodeOptions.disabled = false;
            this.$toast.error(response.data.message);
          } else if (response.data.status === "VALIDATION_FAILED") {
            this.$emit("processingFormSubmission", false);
            this.countryCodeOptions.disabled = false;
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    logoutAllDevices() {
      let params = {
        user_id: this.userId,
      };
      this.isClearingSession = true;
      AuthService.logoutAll(params)
        .then((response) => {
          if (response.data.status == "SUCCESS") {
            this.$toast.success(response.data.message);
            this.isClearingSession = false;
            this.isAllReadyLoggedIn = false;
          }
        })
        .catch((error) => {
          this.error = error;
          this.$store.dispatch("auth/authError");
          this.$store.dispatch("auth/authRequest", false);
        });
    },
    async loginWithOTP() {
      // this.v$.$touch();
      // if (this.v$.$invalid) {
      //   // console.log(this.v$.$errors);
      //   return;
      // }
      let params = {};
      // Custom field validation
      this.v$.otp.$touch();
      if (this.switchUsername) {
        this.v$.email.$touch();
        if (this.v$.email.$invalid || this.v$.otp.$invalid) {
          return;
        } else {
          params = {
            email: this.email,
            otp: this.otp,
            login_from: "WEB",
          };
        }
      } else {
        this.v$.countryCodeOptions.$touch();
        this.v$.phone.$touch();
        if (
          this.v$.countryCodeOptions.$invalid ||
          this.v$.phone.$invalid ||
          this.v$.otp.$invalid
        ) {
          return;
        } else {
          params = {
            country_code: this.countryCodeOptions.value,
            phone: this.phone,
            otp: this.otp,
            login_from: "WEB",
          };
        }
      }
      this.$store.dispatch("auth/authRequest", true);
      this.isProcessing = true;
      this.$emit("processingFormSubmission", true);
      this.countryCodeOptions.disabled = true;
      await AuthService.loginWithOTPVerification(params)
        .then((response) => {
          this.authStatus = response.data.status;
          if (
            this.authStatus == "ERROR" ||
            this.authStatus == "VALIDATION_FAILED"
          ) {
            this.isProcessing = false;
            this.$emit("processingFormSubmission", false);
            this.countryCodeOptions.disabled = false;
            this.$toast.error(response.data.message);
            this.$store.dispatch("auth/authError");
            this.$store.dispatch("auth/authRequest", false);
          } else {
            this.$toast.success(response.data.message);
            const userData = {
              token: response.data.token,
              user: response.data.user,
            };
            let landingPage = "Dashboard";
            if (response.data.user.redirect_to_profile === true) {
              landingPage = "Profile";
            }
            this.$store.dispatch("auth/authSuccess", userData);
            this.$store.dispatch("auth/authRequest", false);
            if (this.$store.state.auth.nextUrl == "") {
              this.$router.push({
                name: landingPage,
              });
            } else {
              this.$router.push(this.$store.state.auth.nextUrl);
              this.$store.dispatch("auth/updateNextUrl", "");
            }
          }
        })
        .catch((error) => {
          this.error = error;
          this.$store.dispatch("auth/authError");
          this.$store.dispatch("auth/authRequest", false);
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
