<template>
  <!-- Form with Password -->
  <form @submit.prevent="switchRoute">
    <div class="mb-3">
      <!-- <label for="email" class="form-label">Email</label> -->
      <input
        type="email"
        class="form-control"
        placeholder="Email"
        v-model="email"
        ref="focusInput"
        aria-describedby="emailHelp"
        :readonly="isEmailValid"
      />
      <div
        class="input-errors"
        v-for="error of v$.email.$errors"
        :key="error.$uid"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3 pass-input" v-if="otpVisibility">
      <!-- <label for="password" class="form-label">Password</label> -->
      <span class="input-actions">
        <a class="show-password">
          <i
            @click="showPassword = !showPassword"
            class="fa"
            :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
            aria-hidden="true"
          ></i>
        </a>
      </span>
      <input
        :type="[showPassword ? 'text' : 'password']"
        class="form-control"
        placeholder="Password"
        v-model="password"
        :readonly="isPasswordResetting"
      />
      <div
        class="input-errors"
        v-for="error of v$.password.$errors"
        :key="error.$uid"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3 pass-input" v-if="otpVisibility">
      <!-- <label for="password_confirmation" class="form-label">Confirm Password</label> -->
      <span class="input-actions">
        <a class="show-password">
          <i
            @click="showConfirmationPassword = !showConfirmationPassword"
            class="fa"
            :class="[showConfirmationPassword ? 'fa-eye' : 'fa-eye-slash']"
            aria-hidden="true"
          ></i>
        </a>
      </span>
      <input
        :type="[showConfirmationPassword ? 'text' : 'password']"
        class="form-control"
        placeholder="Password Confirmation"
        v-model="password_confirmation"
        :readonly="isPasswordResetting"
      />
      <div
        class="input-errors"
        v-for="error of v$.password_confirmation.$errors"
        :key="error.$uid"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3 pass-input" v-if="otpVisibility">
      <span class="input-actions">
        <a href="javascript:void(0);" @click="requestOTP" v-if="isOTPSent"
          >Resend OTP</a
        >
      </span>
      <input
        type="text"
        class="form-control"
        placeholder="- - - - -"
        v-model.trim="otp"
        :readonly="isPasswordResetting"
      />
      <div
        class="input-errors"
        v-for="error of v$.otp.$errors"
        :key="error.$uid"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="text-right cta-section">
      <button
        type="submit"
        v-if="otpVisibility"
        class="btn cta-primary"
        :disabled="isPasswordResetting"
      >
        <span v-if="!isPasswordResetting"> Reset Password </span>
        <span v-else> Resetting... </span>
      </button>
      <button
        type="submit"
        class="btn cta-primary"
        v-if="!otpVisibility"
        :disabled="isOTPRequesting"
      >
        <span v-if="!isOTPRequesting"> Request OTP </span>
        <span v-else> Requesting ... </span>
      </button>
    </div>
  </form>
</template>

<script>
import AuthService from "@/services/AuthService";
import useVuelidate from "@vuelidate/core";
import {
  required,
  // requiredIf,
  email,
  minLength,
  sameAs,
  helpers,
} from "@vuelidate/validators";

export default {
  name: "ForgotPasswordForm",
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      email: "",
      password: "",
      password_confirmation: "",
      otp: "",
      isEmailValid: false,
      isOTPSent: false,
      otpVisibility: false,
      isOTPRequesting: false,
      showPassword: false,
      showConfirmationPassword: false,
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Please enter email", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Please enter password", required),
        minLength: helpers.withMessage(
          "Password should be atleast of 5 characters",
          minLength(5)
        ),
      },
      password_confirmation: {
        required: helpers.withMessage(
          "Please enter confirm password",
          required
        ),
        minLength: helpers.withMessage(
          "Password should be atleast of 5 characters",
          minLength(5)
        ),
        sameAsPassword: helpers.withMessage(
          "Password mismatch",
          sameAs(this.password)
        ),
      },
      otp: {
        // requiredIfOTPVisible: helpers.withMessage(
        //   "Please enter otp",
        //   requiredIf(this.otpVisibility)
        // ),
        required: helpers.withMessage("Please enter otp", required),
      },
    };
  },
  mounted() {
    this.$refs.focusInput.focus();
  },
  methods: {
    // Note:- this is to control the enter key button press by user without clicking on any submit button
    switchRoute() {
      if (!this.otpVisibility) {
        this.requestChangePasswordOTP();
      } else {
        this.resetAccountPassword();
      }
    },
    requestOTP() {
      this.isOTPSent = false;
      this.requestChangePasswordOTP();
    },
    async requestChangePasswordOTP() {
      this.v$.email.$touch();
      if (this.v$.email.$invalid) {
        // console.log(this.v$.$errors);
        return;
      }
      this.isOTPRequesting = true;
      let params = {
        email: this.email,
      };
      await AuthService.requestForgotPasswordOTP(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.otpVisibility = true;
            setTimeout(() => {
              this.isOTPSent = true;
              this.$toast.success(response.data.message);
            }, 5000);
          }
          if (response.data.status === "ERROR") {
            this.isOTPRequesting = false;
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            this.isOTPRequesting = false;
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async resetAccountPassword() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        // console.log(this.v$.$errors);
        return;
      }
      this.isEmailValid = true;
      this.isPasswordResetting = true;
      let params = {
        email: this.email,
        otp: this.otp,
        new_password: this.password,
        new_password_confirmation: this.password_confirmation,
      };
      await AuthService.resetPassword(params)
        .then((response) => {
          // console.log(response);
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.$emit("passwordResetComplete");
          }
          if (response.data.status === "ERROR") {
            this.isEmailValid = false;
            this.isPasswordResetting = false;
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            this.isEmailValid = false;
            this.isPasswordResetting = false;
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
