<template>
  <Head>
    <title v-if="content && content.title !== null">{{ content.title }}</title>
    <title>Login for the Best Online Courses - VB Live</title>
    <meta
      v-if="content && content.meta_description !== null"
      name="description"
      :content="content.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is One of those Online Platforms who gives their Best Coaching for all types of Coachings like NEET, IIT-JEE, CA Foundation, CBSE Tuition and Junior IAS Initiative from Class 6 to Class 10."
    />
    <meta
      v-if="content && content.meta_keywords !== null"
      name="keywords"
      :content="content.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="which online coaching is best for neet, which online coaching is best for jee"
    />
  </Head>
  <div class="login coantainer-fluid">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h3>
            <span>LOGIN</span> <br />
            TO YOUR ACCOUNT
          </h3>
          <div class="mb-3">
            <label for="login_with"
              >Log in with
              <Toggle v-model="toggleUsername.value" v-bind="toggleUsername" />
            </label>
          </div>
          <LoginPasswordForm
            v-if="!isLoginViaOTP && !isPasswordResetViaEmailOTP"
            @showForgotPasswordForm="activateForgotPasswordForm"
            @processingFormSubmission="controlUsernameToggleBtn"
            :switchUsername="switchUsername"
          />
          <LoginOTPForm
            v-if="isLoginViaOTP && !isPasswordResetViaEmailOTP"
            @processingFormSubmission="controlUsernameToggleBtn"
            :switchUsername="switchUsername"
          />
          <ForgotPasswordForm
            v-if="isPasswordResetViaEmailOTP"
            @passwordResetComplete="activateLoginForm"
            @processingFormSubmission="controlUsernameToggleBtn"
            :switchUsername="switchUsername"
          />
          <div class="mb-3 action-div">
            <span
              >Or, try
              <a
                v-if="!isLoginViaOTP"
                href="javascript:void(0);"
                @click="toggleLoginForm"
              >
                Login with OTP
              </a>
              <a
                v-if="isLoginViaOTP"
                href="javascript:void(0);"
                @click="toggleLoginForm"
              >
                Login with Password
              </a>
            </span>
            |
            <span
              >Don’t have an account!
              <router-link :to="{ name: 'Register' }">Register</router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginPasswordForm from "@/components/LoginPasswordForm.vue";
import LoginOTPForm from "@/components/LoginOTPForm.vue";
import ForgotPasswordForm from "@/components/ForgotPasswordForm.vue";
import Toggle from "@vueform/toggle";
import { Head } from "@vueuse/head";

export default {
  name: "Login",
  components: {
    Head,
    Toggle,
    LoginPasswordForm,
    LoginOTPForm,
    ForgotPasswordForm,
  },
  data() {
    return {
      toggleUsername: {
        value: true,
        onLabel: "Email",
        offLabel: "Phone",
        disabled: false,
      },
      // For login page, the user cannot change meta tags from admin side
      content: null,
      isLoginViaOTP: false,
      isPasswordResetViaEmailOTP: false,
    };
  },
  computed: {
    switchUsername() {
      let isEmailLogin = false;
      if (this.toggleUsername.value) {
        isEmailLogin = true;
      }
      return isEmailLogin;
    },
  },
  methods: {
    controlUsernameToggleBtn(status) {
      this.toggleUsername.disabled = status;
    },
    toggleLoginForm() {
      this.isLoginViaOTP = !this.isLoginViaOTP;
      this.isPasswordResetViaEmailOTP = false;
    },
    activateForgotPasswordForm() {
      this.isLoginViaOTP = true;
      this.isPasswordResetViaEmailOTP = !this.isPasswordResetViaEmailOTP;
    },
    activateLoginForm() {
      this.isLoginViaOTP = false;
      this.isPasswordResetViaEmailOTP = !this.isPasswordResetViaEmailOTP;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
<style src="@vueform/toggle/themes/default.css"></style>
